import React from 'react'
import { graphql } from 'gatsby'
import { navigate } from 'gatsby-link'

import Link from '../components/link'
import Layout from '../components/layout'

function encode(data) {
    return Object.keys(data)
        .map(
            key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
        )
        .join('&')
}

export default ({ data, location }) => {
    const post = data.markdownRemark
    const siteTitle = data.site.siteMetadata?.title || `Title`
    const { previous, next } = data

    const [state, setState] = React.useState({})

    const handleChange = e => {
        setState({ ...state, [e.target.name]: e.target.value })
    }

    const handleSubmit = e => {
        e.preventDefault()
        if (!state.email) {
            alert('Please enter your email address.')
            return
        }
        const form = e.target
        fetch('/', {
            method: 'post',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({
                'form-name': form.getAttribute('name'),
                ...state
            })
        })
            .then(() => {
                setState({})
                if (typeof document != 'undefined') {
                    document.getElementById('email').value = ''
                }
                alert('Thank you!')
            })
            .catch(error => alert(error))
    }

    return (
        <Layout
            location={location}
            title={siteTitle}
            description={post.frontmatter.description || post.excerpt}
            darkerHeader="1"
        >
            <article
                className="section section-post"
                itemScope
                itemType="http://schema.org/Article"
            >
                <div className="wrap-post">
                    <header>
                        <h1 itemProp="headline" className="title">
                            {post.frontmatter.title}
                        </h1>
                    </header>
                    <div className="content">
                        <p>{post.frontmatter.date}</p>
                        <section
                            dangerouslySetInnerHTML={{ __html: post.html }}
                            itemProp="articleBody"
                        />
                        <nav>
                            {previous ? (
                                <Link to={previous.fields.slug} rel="prev">
                                    Previous
                                </Link>
                            ) : (
                                <Link to="/blog" rel="prev">
                                    Back to the list
                                </Link>
                            )}
                            {next && (
                                <Link to={next.fields.slug} rel="next">
                                    Next post
                                </Link>
                            )}
                        </nav>
                    </div>
                </div>
            </article>
            <section className="section section-yellow section-subscribe">
                <div className="wrap">
                    <div className="label">Stay in the loop</div>
                    <form
                        name="subscribers"
                        method="post"
                        data-netlify="true"
                        data-netlify-honeypot="bot-field"
                        onSubmit={handleSubmit}
                    >
                        <input
                            type="hidden"
                            name="form-name"
                            value="subscribers"
                        />
                        <input type="hidden" name="bot-field" />
                        <div className="input">
                            <label htmlFor="email">
                                Enter your e-mail to sign up for our newsletter
                            </label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                required
                                onChange={handleChange}
                                placeholder="Your e-mail address"
                            />
                        </div>
                        <button type="submit" className="button">
                            Sign up
                        </button>
                    </form>
                </div>
            </section>
        </Layout>
    )
}

export const pageQuery = graphql`
    query BlogPostBySlug(
        $id: String!
        $previousPostId: String
        $nextPostId: String
    ) {
        site {
            siteMetadata {
                title
            }
        }
        markdownRemark(id: { eq: $id }) {
            id
            excerpt(pruneLength: 160)
            html
            frontmatter {
                title
            }
        }
        previous: markdownRemark(id: { eq: $previousPostId }) {
            fields {
                slug
            }
            frontmatter {
                title
            }
        }
        next: markdownRemark(id: { eq: $nextPostId }) {
            fields {
                slug
            }
            frontmatter {
                title
            }
        }
    }
`
